import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import { setupStore } from "store/store";

import App from "./App";

const store = setupStore();

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<Provider store={store}><App /></Provider>);
