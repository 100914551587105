import { ElementType, FC } from "react";

import { HomePage } from "pages/HomePage";
import { SearchPage } from "pages/Search";
import { OrdersPage } from "pages/Orders";
import { LoginPage } from "pages/Login";
import { PasswordRecoveryPage } from "pages/PasswordRecovery";
import { NewPasswordPage } from "pages/NewPassword";
import { RegisterPage } from "pages/Register";
import { ActivateAccountPage } from "pages/ActivateAccount";
import { ProfilePage } from "pages/Profile";
import { CartPage } from "pages/Cart";
import { OrderOnePage } from "pages/OrderOne";
import { FavoritePage } from "pages/Favorite";
import { PaymentShedulePage } from "pages/PaymentShedule";
import { TBreadCrumbsItem } from "layout/BreadCrumbs";

import { ReactComponent as MenuOrdersIcon } from "assets/img/svg/menuOrders.svg";
import { ReactComponent as MenuFavoriteIcon } from "assets/img/svg/heart.svg";
import { ReactComponent as MenuUserIcon } from "assets/img/svg/user2.svg";
import { ReactComponent as PaymentSheduleIcon } from "assets/img/svg/paymentSchedule.svg";
import { ReactComponent as ReconciliationActIcon } from "assets/img/svg/reconciliationAct.svg";
import { ReactComponent as BalanceIcon } from "assets/img/svg/balance.svg";
import { BalancePage } from "pages/Balance";
import { ReconciliationActPage } from "pages/ReconciliationAct";

export interface IRoute {
  path: string;
  title: string;
  breadCrumbs?: TBreadCrumbsItem[];
  component: FC;
  icon?: ElementType;
}

const dashboardRoutes = {
  path: "/",
  title: "Главная",
  component: HomePage
};

const loginRoutes = {
  path: "/login",
  title: "Логин",
  component: LoginPage
};

const registerRoutes = {
  path: "/register",
  title: "Регистрация",
  component: RegisterPage
};

const passwordRecoveryRoutes = {
  path: "/passwordRecovery",
  title: "Восстановление пароля",
  component: PasswordRecoveryPage
};

const newPasswordRoutes = {
  path: "/newPassword",
  title: "Новый пароль",
  component: NewPasswordPage
};

const successActivateRoutes = {
  path: "/activate",
  title: "Активация аккаунта",
  component: ActivateAccountPage
};

const searchRoutes = {
  path: "/search",
  title: "Поиск детали",
  component: SearchPage
};

const ordersRoutes = {
  path: "/orders",
  title: "Заказы",
  component: OrdersPage,
  icon: MenuOrdersIcon
};

const orderOneRoutes = {
  path: "/orders/:id",
  title: "Заказ",
  breadCrumbs: [{ title: "Заказы", link: "/orders" }],
  component: OrderOnePage
};

const profileRoutes = {
  path: "/profile",
  title: "Личные данные",
  component: ProfilePage,
  icon: MenuUserIcon
};

const cartRoutes = {
  path: "/cart",
  title: "Корзина",
  component: CartPage
};

const favoriteRoutes = {
  path: "/favorite",
  title: "Избранное",
  component: FavoritePage,
  icon: MenuFavoriteIcon
};

const paymentSheduleRoutes = {
  path: "/paymentShedule",
  title: "График платежей",
  component: PaymentShedulePage,
  icon: PaymentSheduleIcon
};

const balanceRoutes = {
  path: "/balance",
  title: "Взаиморасчеты",
  component: BalancePage,
  icon: BalanceIcon
};

const reconciliationActRoutes = {
  path: "/reconciliationAct",
  title: "Запросить акт сверки",
  component: ReconciliationActPage,
  icon: ReconciliationActIcon
};

export const accountRoutes: IRoute[] = [
  dashboardRoutes,
  searchRoutes,
  ordersRoutes,
  orderOneRoutes,
  profileRoutes,
  cartRoutes,
  favoriteRoutes,
  paymentSheduleRoutes,
  balanceRoutes,
  reconciliationActRoutes
];

export const authRoutes: IRoute[] = [
  loginRoutes,
  passwordRecoveryRoutes,
  newPasswordRoutes,
  successActivateRoutes,
  registerRoutes
];

//export const auth2Routes: IRoute[] = [successActivateRoutes, registerRoutes];

export const menuItems: IRoute[] = [
  ordersRoutes,
  favoriteRoutes,
  paymentSheduleRoutes,
  balanceRoutes,
  reconciliationActRoutes
];
