import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

import { BreadCrumbs } from "layout/BreadCrumbs";
import { Spinner2 } from "components/Spinner2";
import { Body } from "layout/Body";
import { PageHeader } from "components/PageHeader";
import { MainBlock } from "layout/MainBlock";
import { useBreakpoint } from "hooks/useBreakpoint";
import { dateFormat, priceFormat } from "utils/format";
import { getBalance } from "services/balanceApi";
import { RightSidebar } from "layout/RightSidebar";

import styles from "./balance.module.scss";

export type TBalanceItem = {
  id: string;
  type: string;
  date: string;
  number: string;
  debet: number;
  credit: number;
  summ: number;
  typeCustomer?: string;
};

const PAGE_SIZE = 20;

export const BalancePage: FC = () => {
  const { breakpoint, isDesktop, isMobile, isTablet } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const getAndSetBalance = (refresh?: boolean) => {
   // setLoading(true);
    getBalance({
      paginationCount: PAGE_SIZE,
      paginationOffset: refresh ? 0 : balance?.length,
    })
      .then((res) => {
        setBalance((prev) => (refresh ? res.items : [...prev, ...res.items]));
        if (res.totalItems <= balance?.length + PAGE_SIZE) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetBalance();
  }, []);

  const infiniteLoadHandler = () => {
    getAndSetBalance();
  };

  const BalanceItem = ({ row }: { row: TBalanceItem }) => {
    return <div className={styles.balanceItem} key={row.id}>
      <div>{row.typeCustomer || row.type}</div>
      <div>{dateFormat(row.date)}</div>
      <div>{row.number}</div>
      <div className={classNames({
        [styles.incoming]: row.summ > 0,
        [styles.outgoing]: row.summ < 0,
      })}>{row.summ > 0 && "+"}{priceFormat(row.summ)}</div>
    </div >;
  };

  return <>
    <BreadCrumbs data={[{ title: "Взаиморасчеты", link: "/balance" }]} />
    <Helmet>
      <title>Взаиморасчеты</title>
    </Helmet>
    <div className={classNames(styles.balance, styles[breakpoint])}>
      {loading && <Spinner2 overlay />}

      <Body>
        <PageHeader title="Взаиморасчеты" className={styles.pageHeader} />

        <MainBlock>
          <div className={styles.balanceHead}>
            <div>Документ</div>
            <div>Дата документа</div>
            <div>Номер документа</div>
            <div>Сумма</div>
          </div>
          <InfiniteScroll
            dataLength={balance?.length}
            next={infiniteLoadHandler}
            hasMore={hasMore}
            loader={<>{hasMore && <Spinner2 />}</>}
            refreshFunction={infiniteLoadHandler}
          >
            {balance && balance.map((row: TBalanceItem) => (
              <BalanceItem row={row} key={row.id} />
            ))}
          </InfiniteScroll>
        </MainBlock>
      </Body>
      {!isMobile && <RightSidebar />}
    </div >
  </>;
}