import { FC, useState, useEffect, forwardRef, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

import { PageHeader } from "components/PageHeader";
import { Spinner2 } from "components/Spinner2";
import { useBreakpoint } from "hooks/useBreakpoint";
import { getOrders, getStatuses } from "services/orderApi";
import useQuery from "hooks/useQuery";
import { dateFormat, dateFormatU, priceFormat } from "utils/format";
import { TStatus } from "pages/OrderOne";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Body } from "layout/Body";
import { MainBlock } from "layout/MainBlock";
import { RightSidebar } from "layout/RightSidebar";
import { ReactComponent as TrashIcon } from "assets/img/svg/trash.svg";
import { ReactComponent as DocumentIcon } from "assets/img/svg/document.svg";
import { BreadCrumbs } from "layout/BreadCrumbs";
import { SelectStatus } from "components/SelectStatus";
import { Spinner } from "components/Spinner";
import { DateRange } from "components/DateRange";

import { ReactComponent as FilterIcon } from "./filter.svg";

import styles from "./orders.module.scss";
import { FilterBottom } from "./FilterBottom";



registerLocale("ru", ru);

export type TOrderRow = {
  id: string;
  number: number;
  admin: string;
  count: number;
  customer: string;
  date: string;
  status: TStatus;
  summ: string;
};

const PAGE_SIZE = 20;

export const OrdersPage: FC = () => {
  const { breakpoint, isDesktop, isMobile, isTablet } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const query = useQuery(useLocation);
  const [orders, setOrders] = useState<TOrderRow[]>([]);
  const [totalSumm, setTotalSumm] = useState("");
  // const [currentPage, setCurrentPage] = useState<number>(
  //   query.get("page") ? Number(query.get("page")) : 1
  // );
  const [search, setSearch] = useState(query.get("search") || "");
  const [dateStart, setDateStart] = useState<string>(query.get("dateStart") ? dateFormat(query.get("dateStart")) : "");
  const [dateEnd, setDateEnd] = useState<string>(query.get("dateEnd") ? dateFormat(query.get("dateEnd")) : "");

  const [status, setStatus] = useState<string[]>(
    query.get("status") ? (query.get("status") as string).split(",") : []);
  const [statuses, setStatuses] = useState<TStatus[]>([]);
  const [number, setNumber] = useState(query.get("number") || "");
  const [hasMore, setHasMore] = useState(true);
  const [shownFilter, setShownFilter] = useState(false);

  const getAndSetOrders = (refresh?: boolean) => {
    getOrders({
      paginationCount: PAGE_SIZE,
      paginationOffset: refresh ? 0 : orders?.length,
      search,
      dateStart,
      dateEnd,
      number,
      status
    })
      .then((res) => {
        setOrders((prev) => (refresh ? res.items : [...prev, ...res.items]));
        setTotalSumm(res.totalSumm);
        if (res.totalItems <= orders?.length + PAGE_SIZE) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  const getAndSetStatuses = () => {
    getStatuses()
      .then(setStatuses)
      .catch((e) => toast.error(e.response.data.message));
  };

  useEffect(() => {
    getAndSetStatuses();
    getAndSetOrders(true);
  }, []);

  const infiniteLoadHandler = () => {
    getAndSetOrders();
  };

  const openOrder = (id: string) => {
    navigate({ pathname: `/orders/${id}` });
  };

  const filterHandler = () => {
    setLoading(true);

    const url = new URLSearchParams();
    if (search) {
      url.append("search", search);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (number) {
      url.append("number", number);
    }

    if (status.length > 0) {
      url.append("status", status.join());
    }

    if (url.toString()) {
      navigate({ pathname: "/orders", search: `?${url}` });
    } else {
      navigate({ pathname: "/orders" });
    }
    getAndSetOrders(true);
    setShownFilter(false);
  };

  const isSearch = () => {
    if (search || dateStart || dateEnd || number || status.length) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isSearch()) {
      setLoading(true);
      getAndSetOrders(true);
    }
  }, [search, dateStart, dateEnd, number, status]);

  const resetFilter = () => {
    setSearch("");
    setDateStart("");
    setDateEnd("");
    setNumber("");
    setStatus([]);
    navigate({ pathname: "/orders" });
    setShownFilter(false);
  };

  const Order = ({ orderRow }: { orderRow: TOrderRow }) => {
    if (isDesktop) {
      return (
        <div
          className={styles.orderItem}
          onClick={() => openOrder(orderRow.id)}
        >
          <div>{orderRow.number}</div>
          <div>{dateFormat(orderRow.date)}</div>
          <div className={styles.document}>
            Показать <span></span>
          </div>
          <div>{orderRow.count} шт.</div>
          <div>{orderRow.admin}</div>
          <div>
            <div
              className={styles.status}
              style={{
                backgroundColor: orderRow.status?.color
              }}
            >
              {orderRow.status?.nameForCustomer}
            </div>
          </div>
          <div>{priceFormat(orderRow.summ)} р.</div>
        </div>
      );
    } else {
      return (
        <div
          className={styles.orderItemMobile}
          onClick={() => openOrder(orderRow.id)}
        >
          <div className={styles.top}>
            <div>
              <div className={styles.orderInfo}>
                № <span>{orderRow.number}</span> Дата{" "}
                <span>{dateFormat(orderRow.date)}</span>
              </div>
              <div>
                Менеджер <span>{orderRow.admin}</span>
              </div>
            </div>
            {isTablet && (
              <div>
                <div className={styles.document}>
                  Документы <DocumentIcon />
                </div>
                <div>
                  <div
                    className={styles.status}
                    style={{
                      backgroundColor: orderRow.status?.color
                    }}
                  >
                    {orderRow.status?.name}
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className={styles.orderCount}>
                <span>{orderRow.count} шт.</span>
              </div>
              <div>
                <span>{priceFormat(orderRow.summ)} р.</span>
              </div>
            </div>
          </div>
          {isMobile && (
            <div className={styles.bottom}>
              <div className={styles.document}>
                Документы <DocumentIcon />
              </div>
              <div>
                <div
                  className={styles.status}
                  style={{
                    backgroundColor: orderRow.status?.color
                  }}
                >
                  {orderRow.status?.nameForCustomer}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  const filterRender = () => (
    <>
      <div className={styles.inputs}>
        <Input
          id="number"
          label="Номер заказа"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          className={styles.input}
        />
        <Input
          id="article"
          label="Артикул"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.input}
        />

        <SelectStatus
          options={statuses}
          value={status}
          onSelect={setStatus}
          className={styles.selectStatus}
          breakpoint={breakpoint}
        />

        <DateRange
          setDtStart={setDateStart}
          setDtEnd={setDateEnd}
          dtStart={dateStart}
          dtEnd={dateEnd}
          className={styles.dateRange}
        />

      </div>
      <div className={styles.filterButtons}>
        <Button
          onClick={filterHandler}
          disabled={!isSearch()}
          breakpoint={breakpoint}
          className={styles.button}
        >
          Показать
        </Button>
        <Button
          onClick={resetFilter}
          disabled={!isSearch()}
          className={styles.resetButton}
          breakpoint={breakpoint}
        >
          <TrashIcon />
          Сбросить фильтр
        </Button>
      </div>
    </>
  );

  return (
    <>
      <BreadCrumbs data={[{ title: "Заказы", link: "/orders" }]} />
      <Helmet>
        <title>Заказы</title>
      </Helmet>
      <div className={classNames(styles.orders, styles[breakpoint])}>
        {loading && <Spinner />}

        <Body>
          <PageHeader title="Заказы" className={styles.pageHeader}>
            {(orders.length > 0 || isSearch()) &&
              <>
                {isDesktop ? (
                  <div className={styles.filter}>{filterRender()}</div>
                ) : (
                  <FilterBottom
                    shown={shownFilter}
                    setShown={setShownFilter}
                    breakpoint={breakpoint}
                  >
                    {filterRender()}
                  </FilterBottom>
                )}
                {!isDesktop && (
                  <Button
                    className={styles.filterButton}
                    breakpoint={breakpoint}
                    onClick={() => setShownFilter(true)}
                  >
                    <FilterIcon />
                    Фильтр
                  </Button>
                )}
              </>
            }
          </PageHeader>

          <MainBlock>
            {orders.length > 0 ?

              <div>
                {isDesktop && (
                  <div className={styles.ordersHead}>
                    <div>№ Заказа</div>
                    <div>Дата</div>
                    <div>Документы</div>
                    <div>Кол-во шт.</div>
                    <div>Менеджер</div>
                    <div>Статус</div>
                    <div>Сумма, руб</div>
                  </div>
                )}
                <InfiniteScroll
                  dataLength={orders?.length}
                  next={infiniteLoadHandler}
                  hasMore={hasMore}
                  loader={<>{hasMore && <Spinner2 />}</>}
                  className={styles.ordersList}
                  refreshFunction={infiniteLoadHandler}
                >
                  {orders.map((order: TOrderRow) => (
                    <Order orderRow={order} key={order.id} />
                  ))}
                </InfiniteScroll>
                <div className={styles.totalSummBlock}>
                  {isDesktop &&
                    <div />
                  }
                  <div>Сумма:</div>
                  <div className={styles.totalSumm}>{priceFormat(totalSumm)}</div>
                </div>
              </div>
              :
              <div className={styles.noOrders}>
                <div className={styles.noOrdersImg} />
                <div className={styles.noOrdersTitle}>Пока нет заказов</div>
                <div className={styles.noOrdersSubTitle}>
                  Тут будет отображаться список ваших заказов
                </div>
              </div>}
          </MainBlock>

        </Body>
        {!isMobile && <RightSidebar />}
      </div >
    </>
  );
};
